import c3api from "../c3api";
import { useToast } from "vue-toastification";
import { GridApi } from "ag-grid-community";
import fileDownload from "js-file-download";

const toast = useToast();

const getColStateKey = (componentId: string): string =>
  `ag-${componentId}-colstate`;
const getColDefKey = (componentId: string): string =>
  `ag-${componentId}-coldef`;
const getFilterKey = (componentId: string): string =>
  `ag-${componentId}-filter`;

interface ColumnDefinition {
  field: string;
  [key: string]: any;
}

interface UrlParams {
  [key: string]: any;
}

interface FilterModel {
  [key: string]: {
    dateFrom?: string;
    type: string;
    filter?: string | string[];
    filterType: string;
    values?: string[];
  };
}

export const saveGridState = (componentId: string, gridApi: GridApi): void => {
  const colState = gridApi.getColumnState();
  const colStateKey = getColStateKey(componentId);
  window.localStorage.setItem(colStateKey, JSON.stringify(colState));

  const colDefs = gridApi.getColumnDefs();
  const colDefKey = getColDefKey(componentId);
  const stringified = JSON.stringify(colDefs);
  window.localStorage.setItem(colDefKey, stringified);

  const filterKey = getFilterKey(componentId);
  const filters = gridApi.getFilterModel();
  window.localStorage.setItem(filterKey, JSON.stringify(filters));
};

export const loadGridState = (
  componentId: string,
  gridApi: GridApi,
  colDefs: ColumnDefinition[],
): void => {
  const colDefKey = getColDefKey(componentId);
  const savedColDefs = JSON.parse(
    window.localStorage.getItem(colDefKey) || "[]",
  );
  let finalColDefs: ColumnDefinition[] = [];
  const savedColDefsMap = constructMapOfColDefs(savedColDefs);
  const newFields: ColumnDefinition[] = [];

  for (let i = 0; i < colDefs.length; i++) {
    let originalColDef = colDefs[i];
    const savedColDef = savedColDefsMap?.get(originalColDef.field);
    if (savedColDef !== undefined) {
      finalColDefs.splice(savedColDef.idx, 0, {
        ...savedColDef.colDef,
        ...originalColDef,
      });
    } else {
      newFields.push(originalColDef);
    }
  }

  if (newFields.length > 0) {
    finalColDefs = [...finalColDefs, ...newFields];
  }

  gridApi.setGridOption("columnDefs", finalColDefs);

  const filterKey = getFilterKey(componentId);
  const savedFilterModel = JSON.parse(
    window.localStorage.getItem(filterKey) || "{}",
  );
  if (savedFilterModel) {
    gridApi.setFilterModel(savedFilterModel);
  }

  const colStateKey = getColStateKey(componentId);
  const savedColState = JSON.parse(
    window.localStorage.getItem(colStateKey) || "[]",
  );
  if (savedColState) {
    gridApi.applyColumnState({ state: savedColState, applyOrder: true });
  }
};

export const addPrefixToId = (
  prefix: string,
  id: string | undefined,
): string | undefined => {
  if (id && id !== undefined) {
    return `${prefix}-${id}`;
  }
};

export const constructMapOfColDefs = (
  colDefs: ColumnDefinition[],
): Map<string, { idx: number; colDef: ColumnDefinition }> => {
  return colDefs?.reduce((acc, item, index) => {
    acc.set(item.field, { idx: index, colDef: item });
    return acc;
  }, new Map());
};

export const resetGridColState = (componentId: string): void => {
  window.localStorage.removeItem(getColStateKey(componentId));
  window.localStorage.removeItem(getColDefKey(componentId));
};

export const resetGridFilterState = (componentId: string): void => {
  window.localStorage.removeItem(getFilterKey(componentId));
};

export const resetGridState = (componentId: string): void => {
  resetGridColState(componentId);
  resetGridFilterState(componentId);
};

const setCachedWarehouseFilters = (
  savedExternalFilters: string,
  urlParams: UrlParams,
): void => {
  let warehouseIds;
  if (savedExternalFilters) {
    let filters = JSON.parse(savedExternalFilters);
    if (typeof filters.warehouseIds == "undefined")
      filters.warehouseIds = { 0: 1 };
    warehouseIds = filters.warehouseIds;
  } else {
    console.log("The filter is empty");
  }
  urlParams.warehouses = warehouseIds;
};

export const setPermissionFilters = (
  savedExternalFilters: string | null,
  urlParams: UrlParams,
): void => {
  if (!savedExternalFilters) {
    console.log("No saved external filters found");
    return;
  }

  try {
    let filterParams: FilterModel = {};
    const parsedFilters = JSON.parse(savedExternalFilters);

    if (parsedFilters.filters && Array.isArray(parsedFilters.filters)) {
      parsedFilters.filters.forEach((obj: string) => {
        if (typeof obj === "string") {
          let valueSplit = obj.split(":");
          if (valueSplit.length === 2) {
            filterParams[valueSplit[0]] = {
              type: "eq",
              filter: valueSplit[1],
              filterType: "text",
            };
          }
        }
      });

      urlParams.filter = { ...urlParams.filter, ...filterParams };
    } else {
      console.log("Invalid filters format in saved external filters");
    }
  } catch (error) {
    console.error("Error parsing saved external filters:", error);
  }
};

export const exportCsv = (gridApi:GridApi) => {
  let loadCount = 0;
  return async function () {
    console.log("exportCsv");
    loadCount++;
    gridApi.showLoadingOverlay();

    const filterModel = gridApi.getFilterModel();
    let sortModel = gridApi.getColumnState().filter((column) => column.sort);
    sortModel = sortModel.map((item) => ({
      colId: item.colId,
      sort: item.sort,
    }));

    const params = {
      request: {
        filterModel: filterModel,
        sortModel: sortModel,
      }
    };

    const urlParams = buildQueryParams(params, null, null, true);
    try {
      const response = await c3api.get("/outbound_orders", {
        params: urlParams,
      });

      console.log("raw response:");
      console.log(response);
      const csv_data = response.data;

      const filename =
        response.headers["content-disposition"].match(
          /filename="?([^"]+)"?/,
        )[1] || "outbound_orders_export.csv";
      fileDownload(csv_data, filename);
    } catch (error) {
      console.error(error);
      toast.error(error);
    } finally {
      loadCount--;
      if (loadCount <= 0) {
        gridApi.hideOverlay();
      }
    }
  };
};


const applyExternalFilterParams = (
  urlParams: UrlParams,
  filterKey: string,
): void => {
  const savedExternalFilters = window.localStorage.getItem(filterKey);
  if (savedExternalFilters) {
    setPermissionFilters(savedExternalFilters, urlParams);
    setCachedWarehouseFilters(savedExternalFilters || "", urlParams);
  }
};

interface BuildQueryParamsOptions {
  request?: {
    sortModel: { colId: string; sort: string }[];
    filterModel: FilterModel;
    startRow: number;
    endRow: number;
  };
}

export const buildQueryParams = function (
  params: BuildQueryParamsOptions,
  filterKey: string,
  advancedFilterKeys: string[],
  csv = false,
): UrlParams {
  let urlParams: UrlParams = {};

  if (params.request) {
    urlParams = {
      sortModel:
        params.request.sortModel.length > 0
          ? params.request.sortModel
          : [{ colId: "id", sort: "desc" }],
      filterModel: params.request.filterModel,
      startRow: params.request.startRow,
      endRow: params.request.endRow,
      batch_size: 100,
      limit: 400,
    };

    const filterParams: FilterModel = {};

    if (params.request.filterModel) {
      for (let [k, v] of Object.entries(params.request.filterModel)) {
        if (advancedFilterKeys && advancedFilterKeys.includes(k)) {
          if (v.filterType === "set") {
            filterParams[k] = {
              filter: v.values,
              type: v.filterType,
              filterType: v.filterType,
            };
          } else {
            filterParams[k] = v;
          }
        } else {
          urlParams[k] = v.filter || v.dateFrom?.substring(0, 10);
        }
      }
    }

    urlParams.filter = filterParams;

    if (typeof params.request.startRow !== "undefined") {
      urlParams.start_row = params.request.startRow;
      urlParams.limit = params.request.endRow - params.request.startRow;
    }
  }

  applyExternalFilterParams(urlParams, filterKey);

  if (csv === true) {
    urlParams.format = "csv";
  }

  return urlParams;
};

interface ServerSideDatasource {
  getRows: (params: any) => Promise<void>;
  maxConcurrentDatasourceRequests: number;
}

export const connectToServerApi = function (): ServerSideDatasource {
  let loadCount = 0;
  return {
    getRows: async (params: any) => {
      const gridApi = params.api;
      loadCount++;
      try {
        const context = params.context;
        const urlParams = buildQueryParams(
          params,
          context.filterKey,
          context.advancedFilterKeys,
        );
        gridApi.showLoadingOverlay();
        const response = await c3api.get(context.apiUrl, { params: urlParams });
        console.log("Response Data:", response.data);
        const data = response.data;

        params.success({
          rowData: data.data,
          rowCount: data.total_count,
        });
      } catch (error: any) {
        console.error(error);
        toast.error(error.response.data.data.join(". "));
        params.fail();
      } finally {
        loadCount--;
        if (loadCount <= 0) {
          gridApi.hideOverlay();
        }
      }
    },
    maxConcurrentDatasourceRequests: 1,
  };
};
